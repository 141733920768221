import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { isRTL } from './Utils/textparse';
import AssetSelect from './Components/assetselect.js';
import SupplierSelect from './Components/supplierselect.js';
import Title from './Title';

import './Inspections.css';

const localInspectionItems = [
    { id: 1, name: 'Assets check', description: 'Check assets if any missing, wear and tear, require maintenance.', remarks: [] },
    { id: 2, name: 'Clients feedback', description: 'Check for complaints from clients (if any).', remarks: [] },
    { id: 3, name: 'Cleaning', description: 'Items found on stairs, parking lots, lobbies, restrooms. Check cleaning of stairs, lobbies, landscape.', remarks: [] },
    { id: 4, name: 'Compliance', description: 'Regulations and compliance to rules. Keep update to new regulations and rules.', remarks: [] },
    { id: 5, name: 'Documentation', description: 'Get all inspections, invoices or receipts from doormen.', remarks: [] },
    { id: 6, name: 'Emergency ready', description: 'Ensure that all staff are aware of emergency plans and drills in case of emergency.', remarks: [] },
    { id: 7, name: 'Environmental', description: 'Solar energy, CO2 emissions, energy wasted, recycling programs, leakages.', remarks: [] },
    { id: 8, name: 'Maintenance', description: 'Adding a check for maintenance schedules or preventive maintenance plans might ensure issues are caught early before becoming critical.', remarks: [] },
    { id: 9, name: 'Safety & Security', description: 'Check CCTV if working, check access control, check firealarm systems, check UPS systems.', remarks: [] },
    { id: 10, name: 'Staff', description: 'Doormen, cleaners, security officers, Uniforms and grooming standards.', remarks: [] }
]

function Inspections({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredinspections, setFilteredinspections] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        //let filtered = project.inspections.filter((inspection) => inspection.description.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.ts - a.ts);
        setFilteredinspections(project.inspections);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddInspection />);
    }

    const editButton = (inspection) => {
        setOption(<EditInspection inspection={inspection} />);
    }

    const removeButton = (inspection) => {
        setOption(<RemoveInspection inspection={inspection} />);
    }

    const viewButton = (inspection) => {
        setOption(<ViewInspection inspection={inspection} />);
    }

    const viewAllButton = (inspections) => {
        setOption(<ViewInspections inspections={inspections} />);
    }

    function AddInspection() {
        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="inspection_addinspectionsuppliersbutton" onClick={() => setOption(<AddSuppliersInspection />)}>Suppliers Inspection</button>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="inspection_addinspectionlocalbutton" onClick={() => setOption(<AddLocalInspection />)}>Local Inspection</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddSuppliersInspection() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [assets, setAssets] = useState([]);
        const [assetid, setAssetid] = useState('');

        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setSuppliers(project.suppliers);
            setAssets(project.assets);
        }, []);

        const addInspection = (timestamp, supplierid, assetid, refno, description) => {
            console.log('Trying to add inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !refno || !description) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedrefno = refno.trim();
            const trimmeddescription = description.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                assetid: assetid,
                refno: trimmedrefno,
                description: trimmeddescription
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUPPLIERINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_INSPECTION',
                            inspection: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Suppliers Inspection</div>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Ref No</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Ref No"
                                    value={refno}
                                    onChange={(e) => setRefno(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => addInspection(timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function AddLocalInspection() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [selectedtab, setSelectedtab] = useState(null);
        const [items, setItems] = useState(localInspectionItems);
        const [progress, setProgress] = useState(0);
        const [localinspectionroption, setLocalinspectionoption] = useState(null);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const addEmptyRemark = (sectionId) => {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === sectionId
                        ? {
                            ...item,
                            remarks: [...item.remarks, { id: Date.now(), location: '', comment: '', picture: null }]
                        }
                        : item
                )
            );
        };

        const handleRemarkClick = (remark, itemid) => {
            setLocalinspectionoption(<EditRemark remark={remark} itemid={itemid} />);
        };

        function EditRemark({ remark, itemid }) {
            const [id, setId] = useState('');
            const [picture, setPicture] = useState(null);
            const [location, setLocation] = useState('');
            const [comment, setComment] = useState('');

            useEffect(() => {
                setId(remark.id);
                setPicture(remark.picture);
                setLocation(remark.location);
                setComment(remark.comment);
            }, [remark]);

            const pickImage = () => {
                document.getElementById('fileInput').click();
            };

            const handleFileChange = (event) => {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const img = new Image();
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');

                            const maxWidth = 800;
                            const maxHeight = 600;
                            let width = img.width;
                            let height = img.height;

                            if (width > height) {
                                if (width > maxWidth) {
                                    height *= maxWidth / width;
                                    width = maxWidth;
                                }
                            } else {
                                if (height > maxHeight) {
                                    width *= maxHeight / height;
                                    height = maxHeight;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(img, 0, 0, width, height);

                            const compressedImage = canvas.toDataURL('image/jpeg', 0.7);
                            setPicture(compressedImage);
                        };
                        img.src = reader.result;
                    };

                    reader.readAsDataURL(file);
                }
            };

            const saveEdit = (id, picture, location, comment) => {
                const updatedRemark = {
                    id,
                    picture,
                    location,
                    comment
                };

                setItems(prevItems =>
                    prevItems.map(item =>
                        item.id === itemid
                            ? {
                                ...item,
                                remarks: item.remarks.map(dataItem =>
                                    dataItem.id === id ? updatedRemark : dataItem
                                )
                            }
                            : item
                    )
                );

                setLocalinspectionoption(null);
            };

            return (
                <div className="localinspection_editremarkmodal">
                    <div className="localinspection_editremarkmodalcontent">
                        <h3>Edit Remark</h3>
                        <input
                            type="text"
                            value={location || ''}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                        />
                        <input
                            type="text"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Comment"
                        />
                        <button onClick={pickImage}>Pick Image</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        {picture && <img src={picture} alt="Preview" style={{ width: '100px' }} />}
                        <button onClick={() => saveEdit(id, picture, location, comment)}>Save</button>
                        <button onClick={() => setLocalinspectionoption(null)}>Cancel</button>
                    </div>
                </div>
            );
        }

        const removeItem = (sectionId, itemId) => {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === sectionId
                        ? {
                            ...item,
                            remarks: item.remarks.filter(dataItem => dataItem.id !== itemId)
                        }
                        : item
                )
            );
        };

        const handleSubmit = async () => {
            console.log('Trying to add inspection');

            const formData = new FormData();
            formData.append('projectuserid', project.projectuserid);
            formData.append('projectid', project.projectid);
            formData.append('ts', timestamp);

            const totalItems = items.length;
            let completedItems = 0;

            const itemsArray = [];

            for (const item of items) {
                const itemData = {
                    id: item.id,
                    name: item.name,
                    remarks: []
                };

                item.remarks.forEach((dataItem) => {
                    itemData.remarks.push(dataItem);
                });

                itemsArray.push(itemData);
                completedItems = completedItems + 1;
            }

            formData.append('items', JSON.stringify(itemsArray));

            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.ADDLOCALINSPECTION, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Local Inspection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className="inspection">
                            {items.map((item, index) => (
                                <div key={item.id} className="inspection_localaccordionitem">
                                    <button
                                        className={index === selectedtab ? 'inspectionheader inspectionheader_active' : 'inspectionheader'}
                                        onClick={() => setSelectedtab(index === selectedtab ? null : index)}
                                    >
                                        {item.name} {item.remarks.length > 0 && "(" + item.remarks.length + ")"}
                                    </button>
                                    {index === selectedtab && (
                                        <div className="accordion_content">
                                            {items[selectedtab].description && (
                                                <div className="tableHeader">
                                                    <span className="tableHeaderText">{items[selectedtab].description}</span>
                                                </div>
                                            )}
                                            <table className="modal_table">
                                                <thead className="tableHeader">
                                                    <tr>
                                                        <th className="tableHeaderText">ID</th>
                                                        <th className="tableHeaderText">Picture</th>
                                                        <th className="tableHeaderText">Location</th>
                                                        <th className="tableHeaderText">Comment</th>
                                                        <th className="tableHeaderText">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items[selectedtab].remarks.map((remark, index) => (
                                                        <tr key={'remark' + index}>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.id}</td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>
                                                                {remark.picture ? <img src={remark.picture} alt="Thumbnail" style={{ width: '50px' }} /> : 'No Image'}
                                                            </td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.location}</td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.comment}</td>
                                                            <td>
                                                                <button
                                                                    className="removeButton"
                                                                    onClick={() => removeItem(items[selectedtab].id, remark.id)}
                                                                >
                                                                    X
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="modal_buttons">
                                                <button className="inspection_localinspectionaddremark" onClick={() => addEmptyRemark(items[selectedtab].id)}>
                                                    Add Remark
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={handleSubmit}>Submit ({progress}%)</button>
                        <button className="modal_button" onClick={() => setOption(null)}>Cancel</button>
                    </div>
                </div>
                {localinspectionroption}
            </div>
        );
    }

    function EditInspection({ inspection }) {
        const [type, setType] = useState('');

        useEffect(() => {
            setType(inspection.type);
        }, [inspection]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    {type == 'supplier' && <EditSupplierInspection inspection={inspection} />}
                    {type == 'local' && <EditLocalInspection inspection={inspection} />}
                </div>
            </div>
        )
    }

    function EditSupplierInspection({ inspection }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [assets, setAssets] = useState([]);
        const [assetid, setAssetid] = useState('');

        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState(inspection.description);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(inspection.id)
            setSelecteddate(new Date(inspection.ts * 1000));
            setSuppliers(project.suppliers);
            setSupplierid(inspection.supplierid);
            setAssets(project.assets);
            setAssetid(inspection.assetid)
            setRefno(inspection.refno);
            setDescription(inspection.description);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const updateInspection = (id, timestamp, supplierid, assetid, refno, description) => {
            console.log('Trying to update supplier inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !refno || !description) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedrefno = refno.trim();
            const trimmeddescription = description.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                supplierid: supplierid,
                assetid: assetid,
                refno: trimmedrefno,
                description: trimmeddescription
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUPPLIERINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update supplier inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_INSPECTION',
                            inspection: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Inspection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Ref No</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Ref No"
                                    value={refno}
                                    onChange={(e) => setRefno(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => updateInspection(id, timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        <button className="modal_button" onClick={() => removeButton(inspection)}>Remove</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function EditLocalInspection({ inspection }) {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [selectedtab, setSelectedtab] = useState(null);
        const [items, setItems] = useState(localInspectionItems);
        const [progress, setProgress] = useState(0);
        const [localinspectionroption, setLocalinspectionoption] = useState(null);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            setId(inspection.id);

            const updatedItems = localInspectionItems.map(item => {
                const matchingItem = inspection.items.find(dataItem => dataItem.id === item.id.toString());
                if (matchingItem) {
                    return { ...item, remarks: matchingItem.remarks };
                }
                return item;
            });

            setItems(updatedItems);
            setSelecteddate(new Date(inspection.ts * 1000));
        }, [inspection]);

        const addEmptyRemark = (sectionId) => {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === sectionId
                        ? {
                            ...item,
                            remarks: [...item.remarks, { id: Date.now(), location: '', comment: '', picture: null }]
                        }
                        : item
                )
            );
        };

        const handleRemarkClick = (remark, itemid) => {
            setLocalinspectionoption(<EditRemark remark={remark} itemid={itemid} />);
        };

        function EditRemark({ remark, itemid }) {
            const [id, setId] = useState('');
            const [picture, setPicture] = useState(null);
            const [location, setLocation] = useState('');
            const [comment, setComment] = useState('');

            useEffect(() => {
                setId(remark.id);
                setPicture(remark.picture);
                setLocation(remark.location);
                setComment(remark.comment);
            }, [remark]);

            const pickImage = () => {
                document.getElementById('fileInput').click();
            };

            const handleFileChange = (event) => {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const img = new Image();
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');

                            const maxWidth = 800;
                            const maxHeight = 600;
                            let width = img.width;
                            let height = img.height;

                            if (width > height) {
                                if (width > maxWidth) {
                                    height *= maxWidth / width;
                                    width = maxWidth;
                                }
                            } else {
                                if (height > maxHeight) {
                                    width *= maxHeight / height;
                                    height = maxHeight;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(img, 0, 0, width, height);

                            const compressedImage = canvas.toDataURL('image/jpeg', 0.7);
                            setPicture(compressedImage);
                        };
                        img.src = reader.result;
                    };

                    reader.readAsDataURL(file);
                }
            };

            const saveEdit = (id, picture, location, comment) => {
                const updatedRemark = {
                    id,
                    picture,
                    location,
                    comment
                };

                setItems(prevItems =>
                    prevItems.map(item =>
                        item.id === itemid
                            ? {
                                ...item,
                                remarks: item.remarks.map(dataItem =>
                                    dataItem.id === id ? updatedRemark : dataItem
                                )
                            }
                            : item
                    )
                );

                setLocalinspectionoption(null);
            };

            return (
                <div className="localinspection_editremarkmodal">
                    <div className="localinspection_editremarkmodalcontent">
                        <h3>Edit Remark</h3>
                        <input
                            type="text"
                            value={location || ''}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                        />
                        <input
                            type="text"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Comment"
                        />
                        <button onClick={pickImage}>Pick Image</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        {picture && <img src={picture} alt="Preview" style={{ width: '100px' }} />}
                        <button onClick={() => saveEdit(id, picture, location, comment)}>Save</button>
                        <button onClick={() => setLocalinspectionoption(null)}>Cancel</button>
                    </div>
                </div>
            );
        }

        const removeItem = (sectionId, itemId) => {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === sectionId
                        ? {
                            ...item,
                            remarks: item.remarks.filter(dataItem => dataItem.id !== itemId)
                        }
                        : item
                )
            );
        };

        const handleSubmit = async () => {
            console.log('Trying to update local inspection');

            const formData = new FormData();
            formData.append('id', id);
            formData.append('projectuserid', project.projectuserid);
            formData.append('projectid', project.projectid);
            formData.append('ts', timestamp);

            const totalItems = items.length;
            let completedItems = 0;

            const itemsArray = [];

            for (const item of items) {
                const itemData = {
                    id: item.id,
                    name: item.name,
                    remarks: []
                };

                item.remarks.forEach((dataItem) => {
                    itemData.remarks.push(dataItem);
                });

                itemsArray.push(itemData);
                completedItems = completedItems + 1;
            }

            formData.append('items', JSON.stringify(itemsArray));

            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.UPDATELOCALINSPECTION, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update local inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Local Inspection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className="inspection">
                            {items.map((item, index) => (
                                <div key={item.id} className="inspection_localaccordionitem">
                                    <button
                                        className={index === selectedtab ? 'inspectionheader inspectionheader_active' : 'inspectionheader'}
                                        onClick={() => setSelectedtab(index === selectedtab ? null : index)}
                                    >
                                        {item.name} {item.remarks.length > 0 && "(" + item.remarks.length + ")"}
                                    </button>
                                    {index === selectedtab && (
                                        <div className="accordion_content">
                                            {items[selectedtab].description && (
                                                <div className="tableHeader">
                                                    <span className="tableHeaderText">{items[selectedtab].description}</span>
                                                </div>
                                            )}
                                            <table className="modal_table">
                                                <thead className="tableHeader">
                                                    <tr>
                                                        <th className="tableHeaderText">ID</th>
                                                        <th className="tableHeaderText">Picture</th>
                                                        <th className="tableHeaderText">Location</th>
                                                        <th className="tableHeaderText">Comment</th>
                                                        <th className="tableHeaderText">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items[selectedtab].remarks.map((remark, index) => (
                                                        <tr key={'remark' + index}>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.id}</td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>
                                                                {remark.picture ? <img src={remark.picture} alt="Thumbnail" style={{ width: '50px' }} /> : 'No Image'}
                                                            </td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.location}</td>
                                                            <td onClick={() => handleRemarkClick(remark, items[selectedtab].id)}>{remark.comment}</td>
                                                            <td>
                                                                <button
                                                                    className="removeButton"
                                                                    onClick={() => removeItem(items[selectedtab].id, remark.id)}
                                                                >
                                                                    X
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="modal_buttons">
                                                <button className="inspection_localinspectionaddremark" onClick={() => addEmptyRemark(items[selectedtab].id)}>
                                                    Add Remark
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={handleSubmit}>Submit ({progress}%)</button>
                        <button className="modal_button" onClick={() => removeButton(inspection)}>Remove</button>
                        <button className="modal_button" onClick={() => setOption(null)}>Cancel</button>
                    </div>
                </div>
                {localinspectionroption}
            </div>
        );
    }

    function RemoveInspection({ inspection }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(inspection.id)
        }, [inspection]);

        const removeInspection = (id) => {
            console.log('Trying to remove inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_INSPECTION',
                            inspectionid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeInspection(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewInspection({ inspection }) {
        const [type, setType] = useState('');

        useEffect(() => {
            setType(inspection.type);
        }, [inspection]);


        return (
            <div className='modal'>
                <div className='modal_content'>
                    {type == 'supplier' && <ViewSupplierInspection inspection={inspection} />}
                    {type == 'local' && <ViewLocalInspection inspection={inspection} />}

                </div>
            </div>
        )
    }

    function ViewSupplierInspection({ inspection }) {
        const [ts, setTs] = useState('');
        const [type, setType] = useState('');
        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState(inspection.description);

        const printRef = useRef();

        const printButton = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            setTs(inspection.ts);
            setType(inspection.type);
            setRefno(inspection.refno);
            setDescription(inspection.description);
        }, [inspection]);

        return (
            <div className='modal_scrollable'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_title'>Inspection</div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {moment.unix(inspection.ts).format('D/MMM/YYYY')}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Type</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {type}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Description</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='inspections_description'>{description}</div>
                        </div>
                    </div>
                </div>
                <div className="modal_buttonscontainer">
                    <ReactToPrint
                        trigger={() => (
                            <button className="modal_button" onClick={printButton}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                    <button className="modal_button" onClick={cancelButton}>Cancel</button>
                </div>
            </div>
        )
    }

    function ViewLocalInspection({ inspection }) {
        const [ts, setTs] = useState('');
        const [type, setType] = useState('');
        const [items, setItems] = useState([]);
        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState(inspection.description);

        const printRef = useRef();

        const printButton = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            setTs(inspection.ts);
            setType(inspection.type);
            setItems(inspection.items);
            setRefno(inspection.refno);
            setDescription(inspection.description);
        }, [inspection]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Local Inspection</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Date</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {moment.unix(inspection.ts).format('D/MMM/YYYY')}
                                </div>
                            </div>
                            <table className="modal_table">
                                <thead className="tableHeader">
                                    <tr>
                                        <th className="tableHeaderText">Picture</th>
                                        <th className="tableHeaderText">Location</th>
                                        <th className="tableHeaderText">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items.map((item, index) => (
                                            <React.Fragment key={'item' + index}>
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                {
                                                    item.remarks.map((remark, index) => (
                                                        <tr key={'remark' + index}>
                                                            <td></td>
                                                            <td>{remark.location}</td>
                                                            <td>{remark.comment}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={printButton}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function ViewInspections({ inspections }) {
        const [supplierid, setSupplierid] = useState('');
        const [assetid, setAssetid] = useState('');
        const [filteredInspections, setFilteredInspections] = useState([]);

        const printRef = useRef();

        const printButton = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            const updatedFilteredInspections = supplierid === 0 && assetid === 0
                ? inspections
                : inspections.filter((inspection) => {
                    return (supplierid === '' || inspection.supplierid === supplierid) &&
                        (assetid === '' || inspection.assetid === assetid);
                });

            updatedFilteredInspections.sort((a, b) => b.ts - a.ts);

            setFilteredInspections(updatedFilteredInspections);
        }, [supplierid, assetid]);

        const saveInspectionsPDF = async () => {
            const content = printRef.current;

            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'inspections_' + project.name + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Inspections</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Supplier</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} position='fixed' width={200} />
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Asset</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} position='fixed' width={200} />
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead><tr><th>Date</th><th>Ref</th><th>Supplier</th><th>Asset</th><th>Description</th></tr></thead>
                                <tbody>
                                    {
                                        filteredInspections.map((inspection, index) => {
                                            const date = moment.unix(inspection.ts).format('D/MMM/YYYY');
                                            const refno = inspection.refno;
                                            const supplierName = project.suppliers.find(supplier => supplier.id == inspection.supplierid)?.name || 'Supplier not found';
                                            const assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;
                                            const description = inspection.description;

                                            return (
                                                <tr key={'inspection' + index}>
                                                    <td><div>{date}</div></td>
                                                    <td><div>{refno}</div></td>
                                                    <td><div>{supplierName}</div></td>
                                                    <td><div>{assetName}</div></td>
                                                    <td><div className='inspections_description'>{description}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={printButton}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={saveInspectionsPDF}>Save as PDF</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div className='inspections'>
            <Title text='Inspections' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Inspection</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewAllButton(project.inspections)}>View Inspections</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredinspections.map((inspection, index) => {
                            let supplierName = project.suppliers.find(supplier => supplier.id == inspection.supplierid)?.name;
                            let assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;

                            return (
                                <tr key={'inspection' + index}>
                                    <td><div className='table_button' onClick={() => editButton(inspection)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(inspection)}>View</div></td>
                                    <td><div>{inspection.id}</div></td>
                                    <td><div>{moment.unix(inspection.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{inspection.type}</div></td>
                                    <td><div className='inspections_description'>{inspection.description}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Inspections;